<template>
  <v-layout align-center justify-center row>
    <v-flex xs6>
      <Fields
        :fields="fields"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'FieldsTest',
  data() {
    return {
      fields: [
        {
          component: 'dateField',
          label: 'fields.withoutValue',
          color: 'accent',
        },
        {
          component: 'dateField',
          value: '1988-12-22',
          color: 'accent',
        },
        {
          component: 'numberField',
          label: 'fields.withoutValue',
          color: 'accent',
        },
        {
          component: 'numberField',
          value: 185,
          color: 'accent',
        },
        {
          component: 'numberField',
          label: 'fields.withvalidation',
          color: 'accent',
          value: 185,
          options: {
            min: -10,
            max: 100,
            step: 0.5,
          },
        },
        {
          component: 'numberField',
          label: 'fields.withValidationNoMax',
          color: 'accent',
          value: 185,
          options: {
            min: -10,
            step: 0.5,
          },
        },
        {
          component: 'sliderField',
          label: 'fields.withvalidation',
          trackColor: 'accent',
          value: 50,
          options: {
            min: -10,
            max: 100,
            step: 0.5,
          },
        },
        {
          component: 'timeField',
          label: 'fields.withvalidation',
          color: 'accent',
          value: '13:09',
          icon: 'ffly4u-device',
        },
        {
          component: 'textareaField',
          label: 'fields.withvalidation',
          color: 'accent',
          value: '13:09',
        },
        {
          component: 'textField',
          label: 'fields.withvalidation',
          color: 'accent',
          value: '13:09',
        },
        {
          component: 'fileField',
          label: 'fields.withoutValue',
          color: 'accent',
        },
        {
          component: 'checkBoxField',
          label: '1 checkbox',
          color: 'accent',
          icon: 'mdi-car',
          value: true,
        },
        {
          component: 'checkBoxField',
          label: '2 checkbox',
          icon: 'mdi-car',
          color: 'accent',
          value: [true, false],
          options: [
            {
              label: 'checkbox 1',
              color: 'warning',
              icon: 'mdi-car',
            },
            {
              label: 'checkbox 2',
              color: 'error',
              icon: 'mdi-ferry',
            },
          ],
        },
        {
          component: 'checkBoxField',
          label: '2 checkbox',
          color: 'accent',
          icon: '',
          value: [true, false],
          options: [
            {
              label: 'checkbox 1',
              color: 'warning',
            },
            {
              label: 'checkbox 2',
              color: 'error',
            },
          ],
        },
        {
          component: 'radioButtonField',
          label: 'fields.withoutValue',
          tooltip: 'fields.withoutValue',
          icon: '',
          color: 'accent',
          options: [
            {
              id: '1',
              title: 'fields.withoutValue',
              label: 'fields.withoutValue',
              color: 'error',
            },
            {
              id: '2',
              title: 'fields.withoutValue',
              label: 'fields.withvalidation',
              color: 'accent',
            },
            {
              id: '3',
              title: 'fields.withoutValue',
              label: 'fields.withoutValue',
              color: 'accent',
            },
          ],
        },
        {
          component: 'switchField',
          label: 'fields.withvalidation',
          tooltip: 'fields.withoutValue',
          value: [true, false, true],
          options: [
            {
              color: 'accent',
              label: 'fields.withvalidation',
            },
            {
              color: 'accent',
              label: 'fields.withvalidation',
            },
            {
              color: 'accent',
              label: 'fields.withvalidation',
            },
          ],
        },
        {
          component: 'dateTimeField',
          label: 'fields.withvalidation',
          color: 'accent',
        },
        {
          component: 'selectIconsField',
          label: 'fields.withvalidation',
          color: 'accent',
          icons: [
            'fa-500px',
            'fa-address-book',
            'fa-address-book-o',
            'fa-address-card',
            'fa-address-card-o',
            'fa-adjust',
            'fa-adn',
            'fa-align-center',
            'fa-align-justify',
            'fa-align-left',
            'fa-align-right',
            'fa-amazon',
            'fa-ambulance',
            'fa-american-sign-language-interpreting',
            'fa-anchor',
            'fa-android',
            'fa-angellist',
            'fa-angle-double-down',
            'fa-angle-double-left',
            'fa-angle-double-right',
            'fa-angle-double-up',
            'fa-angle-down',
            'fa-angle-left',
            'fa-angle-right',
            'fa-angle-up',
            'fa-apple',
            'fa-archive',
            'fa-area-chart',
          ],
        },
        // {
        //   component: 'mapField',
        //   color: 'accent',
        //   value: {},
        // },
        {
          component: 'selectField',
          value: ['test'],
          options: {
            items: ['test', 'toto'],
            multiple: true,
          },
        },
        {
          component: 'selectField',
          value: 'test',
          options: {
            items: ['test', 'toto'],
            multiple: false,
          },
        },
      ],
    };
  },
};
</script>
